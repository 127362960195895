.footer-container{
    position: relative;
}

.footer-container>hr{
    border: 1px solid lightgray;
}
.footer{
    display: flex;
    padding: 1rem 2rem ;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}
.social{
    cursor: pointer;
}

.logo-footer>img{

   width: 15rem; 
}

.blur-f-1{
  bottom: 0rem;
  right: 15%;
  width: 26rem;  
  height: 12rem;
  filter: blur(200px);
  background: red;
}

.blur-f-2{
    bottom: 0rem;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}